import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { BaseImage } from '@alterpage/gatsby-plugin-image';

import {
    container,
    text,
    paymentProviders,
    paymentIcon,
    icons,
    apaczkaIcon,
} from './summary-disclaimer.module.scss';

import PaymentMethodsIcon from '../../assets/images/svg/payment-methods.svg';

import { getImage, ImageDataLike } from 'gatsby-plugin-image';

import { useFeatures } from '../../hooks/use-features';
import useTranslations from '../../hooks/use-translations';

import Markdown from '../hoc/markdown';

export interface ISummaryDisclaimerProps {
    className?: string;
    children?: React.ReactNode;
    firstInfo?: string | null;
    secondInfo?: string | null;
}

interface IApaczkaLogoQueryResult {
    file: ImageDataLike | null;
}

const SummaryDisclaimer: React.FC<ISummaryDisclaimerProps> = ({
    className = '',
    firstInfo,
    secondInfo,
    children,
}) => {
    const t = useTranslations('SummaryDisclaimer');
    const [isApaczkaEnabled] = useFeatures(['apaczka']);
    const { file } = useStaticQuery<IApaczkaLogoQueryResult>(query);

    return (
        <div className={`${container} ${className}`}>
            {firstInfo && (
                <Markdown
                    className={text}
                    components={{
                        a: ({ href, children }) => (
                            <a href={href} target="_blank" rel="noreferrer noopener">
                                {children}
                            </a>
                        ),
                    }}
                >
                    {firstInfo}
                </Markdown>
            )}
            {children}
            <div className={paymentProviders}>
                <p>{t.providerInfo}</p>
                <div className={icons}>
                    <PaymentMethodsIcon className={paymentIcon} />
                    {isApaczkaEnabled && (
                        <BaseImage className={apaczkaIcon} image={getImage(file)} />
                    )}
                </div>
            </div>
            {secondInfo && (
                <Markdown
                    className={text}
                    components={{
                        a: ({ href, children }) => (
                            <a href={href} target="_blank" rel="noreferrer noopener">
                                {children}
                            </a>
                        ),
                    }}
                >
                    {secondInfo}
                </Markdown>
            )}
        </div>
    );
};

const query = graphql`
    query {
        file(relativePath: { eq: "apaczka.png" }) {
            childImageSharp {
                gatsbyImageData(placeholder: NONE, quality: 95)
            }
        }
    }
`;

export default SummaryDisclaimer;
